import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Header from "./Header";
import Users from "./Users";
import { faUser } from '@fortawesome/free-solid-svg-icons';


const Profile = () => {

    return (

        <>
            <Header />
            <div className='grid md:grid-cols-7 w-full pt-20 md:pl-4 md:pr-52 overflow-y-auto'>
                <div className="md:col-span-7 bg-white md:p-4 rounded-md md:rounded-l-md md:rounded-r-none">
                <span className='inline-block  place-content-center mr-4 text-blue-800 text-lg ml-2'><FontAwesomeIcon icon={faUser} /></span>
                <h3 className="inline-flex items-center">پروفایل</h3>
                        <p className=' px-8'>
                        مشخصات کاربر در این بخش قابل مشاهده است.
                        </p>
                    <Users />
                </div>
            </div>
        </>
    )
}

export default Profile