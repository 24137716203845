import { useState, useEffect } from "react";
import ReactPaginate from 'react-paginate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SinUser from "./SinUser";
import { faUserGroup } from "@fortawesome/free-solid-svg-icons";
import { axiosPrivate } from "../Components/Api/axios";



const Users = () => {
    const [users, setUsers] = useState();
    const [loading, setLoading] = useState(false);
    // const navigate = useNavigate();
    // const location = useLocation();

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const getUsers = async () => {
            try {
                setLoading(true);
                const response = await axiosPrivate.get('/auth/user', {
                    signal: controller.signal
                });
                // console.log(response.data.data);
                isMounted && setUsers(response.data.data);
                setLoading(false);
            } catch (err) {
                console.error(err);
                // navigate('/sign-in', { state: { from: location }, replace: true });
            }
        }

        getUsers();

        return () => {
            isMounted = false;
            isMounted && controller.abort();
        }
    }, [])

    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const itemsPerPage = 10;

    useEffect(() => {
        setTotalPages(Math.ceil(users?.length / itemsPerPage));
    }, []);

    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const subset = users?.slice(startIndex, endIndex);
    console.log(subset?.length);


    const handlePageChange = (selectedPage) => {
        setCurrentPage(selectedPage.selected);
    };

    return (
            <SinUser />
    )
}

export default Users
