import axios from 'axios';

const BASE_URL = 'https://api.saber24.ir';

// Create an instance of axios
const axiosInstance = axios.create({
    baseURL: BASE_URL,
    headers: {
        // 'Content-Type': 'application/json',
    },
});

// Create a private axios instance with dynamic token retrieval
export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-Type': 'application/json' },
    // withCredentials: true
});

// Add an interceptor to inject the token dynamically into the request
axiosPrivate.interceptors.request.use(
    config => {
        const token = localStorage.getItem('access');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

export default axiosInstance;
