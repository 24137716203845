import { useState, useEffect } from "react";
import { axiosPrivate } from "../Components/Api/axios";
import axiosInstance from "../Components/Api/axios";

const SinUser = () => {
    const [sinUser, setSinUsers] = useState();
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const getUsers = async () => {
            try {
                setLoading(true);
                const response = await axiosPrivate.get('/auth/user', {
                });
                // console.log(response.data.data);
                isMounted && setSinUsers(response?.data);
                console.log(response.data);
                console.log(sinUser);
                setLoading(false);
            } catch (err) {
                console.error(err);
                // navigate('/sign-in', { state: { from: location }, replace: true });
            }
        }

        getUsers();
        console.log(sinUser);
        return () => {
            isMounted = false;
            isMounted && controller.abort();
        }
    }, [])

    console.log(sinUser);

    return (

        <>
            {loading ? <div className='flex'>< p className=' text-right text-lg mt-2 pr-6' > در حال بارگذاری...</p ></div>
                : <div className="profile-container grid grid-cols-6">
                    <div className="col-span-4 col-start-2 content-center bg-purple-100 p-4 mt-4 rounded-md">
                        <h6 className="m-0 ">نام کاربر: {sinUser?.name}
                        </h6>
                        <p className="m-0 text-sm cursor-pointer mt-1 text-gray-600">
                            شناسه کاربر: {sinUser?.id}
                        </p>
                        <p className="m-0 text-sm cursor-pointer mt-1 text-gray-600">
                            ایمیل: {sinUser?.email}
                        </p>
                        <p className="m-0 text-sm cursor-pointer mt-1 text-gray-600">
                            دسترسی: {sinUser?.role}
                        </p>
                        <p className="m-0 text-sm cursor-pointer mt-1 text-gray-600">
                            تاریخ ساخت: {sinUser?.created_at}
                        </p>
                    </div>
                </div>}
        </>

    )
}

export default SinUser
