import { useContext, useState, useRef } from "react";
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { axiosPrivate } from "../Components/Api/axios";
import AuthContext from './Context/AuthProvider';
import { faClipboardQuestion } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BulkInp from "./BulkInp";

function InpInqWhatsapp() {

    const { setDetShow, setSocailCh, setShow, setTableShow, setSubAlert, setInqShow, setInqData, setAccData } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const inputRef = useRef(null);

    const [phoneData, setPhoneData] = useState({
        phone: '',
        country: '',
        countryCode: ''
    });

    const handlePhoneChange = (value) => {
        console.log("Phone data changed:", value); // Check what is returned

        // Update the state accordingly
        setPhoneData((prevState) => ({
            ...prevState,
            phone: value,
            // You may want to manually set country and countryCode if required
            country: 'IR', // Manually set to Iran if required
            countryCode: '+98', // Manually set to Iran code if required
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const cleanPhone = phoneData.phone.startsWith('+') ? phoneData.phone.substring(1) : phoneData.phone;
        console.log("Cleaned Phone Number:", cleanPhone); // Logs the cleaned phone number without '+'

        if (!cleanPhone) {
            console.log("Phone data is empty, submission blocked.");
            return;
        }

        try {
            setLoading(true);
            console.log("Sending API request to:", `/check/mobile/${cleanPhone}`);
            const response = await axiosPrivate.get(`/check/mobile/${cleanPhone}`);

            console.log("API response received:", response.data);

            if (response?.data?.code === "200") {
                setInqData(response.data);
                setAccData(response.data?.data[0]);
                setShow(false);
                setSocailCh("whatsapp");
                setTableShow(true);
                setSubAlert(true);
                setInqShow(true);
                setDetShow(false);
            }

            setLoading(false);
        } catch (err) {
            console.error("API request failed:", err);
            setLoading(false);
        }

        // Clear the input after submission
        setPhoneData({
            phone: '',
            country: '',
            countryCode: ''
        });
        inputRef.current.value = '';
    };

    return (
        <section className="inq-result">
            <div className="">
                <span className='inline-block place-content-center mr-4 text-blue-800 dark:text-blue-300 text-lg ml-2'>
                    <FontAwesomeIcon icon={faClipboardQuestion} />
                </span>
                <h5 className="inline-flex items-center">استعلام اکانت</h5>
                <div className="">
                    <div className="mt-4">
                        <div className="lg:w-1/2 inq-holder">
                            <div className="mx-auto text-center">
                                <form onSubmit={handleSubmit} className="flex items-center justify-center">
                                    <label className="ml-2">ورود مشخصه تکی:</label>
                                    <div className="inline-block" style={{ "direction": "ltr" }}>
                                        <PhoneInput
                                            defaultCountry="ir" // Set default country to Iran
                                            value={phoneData.phone}
                                            onChange={handlePhoneChange}
                                            showCountryFlag={true}
                                            showCountryCode={true}
                                            ref={inputRef}
                                        // style={{ width: '300px', height: '45px', fontSize: '16px' }} // Customize size here
                                        />
                                    </div>
                                    <button
                                        type="submit"
                                        className={`py-2 px-4 h-10 subBut text-white hover:bg-yellow bg-light-green rounded mr-2 cursor-pointer items-center`}
                                        disabled={loading} // Optionally disable button while loading
                                    >
                                        {loading ? 'در حال ارسال...' : 'ثبت'}
                                    </button>
                                </form>
                            </div>
                        </div>
                        <BulkInp />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default InpInqWhatsapp;
